import React from "react";
import {graphql, StaticQuery} from "gatsby";

export default (props) => (
	<StaticQuery
		query={graphql`
      query {
				 site {
						 siteMetadata {
								 product {
										 version
								 }
						 }
				 }
      }
    `}
		render={(data) => <a href={"https://www.pseekoo.at/battery-finder/battery-finder-" + props.stage + "-v" + data.site.siteMetadata.product.version + ".html" }>{props.title}</a>}
	/>
);